import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const FourOhFourStyles = styled.div`
  text-align: center;
  h2 {
    margin-top: 2rem;
    font-size: 20rem;
    font-weight: 600;
    height: 22rem;
    color: black;
    letter-spacing: 1rem;
    @media (max-width: 414px) {
      font-size: 15rem;
      height: 17rem;
    }
  }
  p {
    margin-top: 1.5rem;
    font-size: 3.5rem;
    font-weight: 300;
    color: black;
    @media (max-width: 414px) {
      margin-top: 1rem;
      font-size: 3.2rem;
    }
  }
  a {
    display: inline-block;
    margin-top: 5rem;
    font-size: 2rem;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    padding: 1.2rem 2rem;
    &:hover {
      background-color: black;
      color: white;
    }
  }
`;

export const FourOhFour = () => (
  <FourOhFourStyles>
    <h2>404</h2>
    <p>Page not found</p>
    <Link to="/">Go home</Link>
  </FourOhFourStyles>
);
