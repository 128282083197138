import React from 'react';
import { FourOhFour } from '../components/FourOhFour';
import SEO from '../components/SEO';

export default function FourOhFourPage() {
  return (
    <>
      <SEO title="Page Not Found" />
      <FourOhFour />
    </>
  );
}
